import React from 'react'
import ProductFlag from './product-flag'

const ProductKey = ({ flags }) => (
  <div className="product-key">
    {flags.map((flag, index) => (
      <div className="product-key__tag" key={flag.uid}>
        <ProductFlag flag={flag} withTooltip={false} />
        {flag.title}
      </div>
    ))}
  </div>
)

export default ProductKey
