import React, { Component } from 'react'
import ProductSummary from './product-summary'
import sortBy from 'lodash/sortBy'
import { bind } from 'decko'
import qs from 'query-string'
import ProductToolbar from './product-toolbar'
import ProductKey from '../product-key'

const DEFAULT_FILTER_MAP = {
  all: () => true,
}

const DEFAULT_FILTER_FIELDS = {
  all: 'Filter By',
}

const SORT_FIELDS = {
  none: 'Sort By',
  product_name: 'Name',
}

export default class ProductListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterField: 'all',
      sortField: null,
    }
  }

  componentDidMount() {
    const queryString = qs.parse(window.location.search)

    this.setState({
      filterField: queryString.filterBy || 'all',
      sortField: queryString.sortBy || null,
    })
  }

  /**
   * @param {string} field
   */
  @bind
  filter(field) {
    this.setState({ filterField: field })

    const queryString = qs.parse(window.location.search)
    queryString.filterBy = field
    window.history.pushState(
      {},
      null,
      `${window.location.pathname}?${qs.stringify(queryString)}`,
    )
  }

  /**
   * @param {string} field
   */
  @bind
  sort(field) {
    this.setState({ sortField: field })

    const queryString = qs.parse(window.location.search)
    queryString.sortBy = field
    window.history.pushState(
      {},
      null,
      `${window.location.pathname}?${qs.stringify(queryString)}`,
    )
  }

  render() {
    let { products } = this.props
    const flags = products.reduce((acc, product) => {
      if (!product) {
        return acc
      }

      const productFlags =
        product.data?.flags?.map(flag => ({
          uid: flag?.flag?.document?.uid,
          ...flag.flag?.document?.data,
        })) || []

      return [
        ...acc,
        ...productFlags.filter(flag => !acc.some(f => f.uid === flag.uid)),
      ]
    }, [])

    const filterFields = {
      ...DEFAULT_FILTER_FIELDS,
    }
    const filterMap = {
      ...DEFAULT_FILTER_MAP,
    }
    for (const flag of flags) {
      filterFields[flag.uid] = flag.title
      filterMap[flag.uid] = product =>
        product.data?.flags?.some(item => item.flag?.document?.uid === flag.uid)
    }

    products = products
      .filter(item => !!item)
      .filter(filterMap[this.state.filterField])
    products = sortBy(products, product => {
      const data = product.data[this.state.sortField] || {}

      if ('text' in data) {
        return data.text
      }

      return data
    })

    return (
      <section className="product-listing">
        <div className="product-listing__container container">
          <ProductToolbar
            filterFields={filterFields}
            onFilterChange={this.filter}
            sortFields={SORT_FIELDS}
            onSortChange={this.sort}
            count={products.length}
          />

          <ProductKey flags={flags} />

          <ul className="product-listing__products">
            {products.map((product, index) => (
              <ProductSummary
                className="product-listing__product"
                product={product}
                key={index}
              />
            ))}
          </ul>
        </div>
      </section>
    )
  }
}
