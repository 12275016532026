import React from 'react'
import { Image } from '@superrb/gatsby-addons/components'

const ProductFlag = ({ flag, withTooltip = true }) => (
  <button className="product-flag">
    <Image className="product-flag__icon" image={flag.icon} />
    {withTooltip && <span className="product-flag__tooltip">{flag.title}</span>}
  </button>
)

export default ProductFlag
